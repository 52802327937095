<template>
  <section>
    <v-row>
      <!-- FORMULARIO PARA REGISTRAR ACCIONISTA JURÍDICO -->
      <v-col cols="12" lg="12">
        <!-- Selector de tipo de documento -->
        <v-row>
          <v-col cols="6">
            <v-select
              :error-messages="tipoDocumentoError"
              @change="cambiarFormatoDocumento"
              v-model="formulario.id_tipo_documento"
              :items="tiposDocumentosJuridico"
              label="Tipo de documento*"
              outlined
              item-text="nombre"
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <!-- Campo para registrar el nit -->
          <v-col cols="6">
            <v-text-field
              :disabled="!formulario.id_tipo_documento"
              :error-messages="nitError"
              v-model="formulario.numero_documento"
              label="Número de identificación*"
              outlined
              :placeholder="formatoDocumento"
            ></v-text-field>
          </v-col>

          <!-- Razón social -->
          <v-col cols="6">
            <v-text-field
              :error-messages="razonSocialError"
              v-model="formulario.primer_nombre"
              label="Razón social*"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <!-- Porcentaje de participación -->
          <v-col cols="6">
            <v-text-field
              @keypress="this.filterNumber"
              prepend-inner-icon="mdi-percent-outline"
              :error-messages="porcentajeParticipacionError"
              v-model="formulario.porcentaje_participacion"
              label="Porcentaje de participación*"
              outlined
            ></v-text-field>
          </v-col>

          <!-- Matrícula de comercio -->
          <v-col cols="6">
            <v-text-field
              :error-messages="matriculaComercioError"
              v-model="formulario.matricula_comercio"
              label="Matrícula de comercio*"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="6">
            <v-btn
              :loading="guardarAccionistaLoading"
              color="secondary white--text"
              @click="guardarAccionista"
              >Agregar accionista</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

const validarFormatoRegex = (formato) => {
  const validaciones = formato.split('|');
  const expresiones = validaciones.map((validacion) => {
    return new RegExp(`^${validacion.replace(/#/ig, '\\d')}$`);
  });

  return (value) => expresiones.some((expresion) => expresion.test(value));
};

export default {
  name: "AgregarAccionistaJuridico",
  data: () => ({
    formulario: {
      id_tipo_persona: 2,
      id_tipo_documento: null,
      numero_documento: null,
      porcentaje_participacion: null,
      agente_legal: false,
      primer_nombre: null,
      primer_apellido: "",
      matricula_comercio: null,
    },
    guardarAccionistaLoading: false,
    tiposDocumentos: [],
    formatoDocumento: "",
  }),
  validations() {
    return ({
      formulario: {
        id_tipo_documento: { required },
        numero_documento: {
          required,
          ...this.formatoDocumento && ({ regex: validarFormatoRegex(this.formatoDocumento) })
        },
        porcentaje_participacion: {
          required,
        },
        primer_nombre: {
          required,
        },
        matricula_comercio: {
          required,
        },
      },
    });
  },
  computed: {
    tipoDocumentoError() {
      const errors = [];
      if (!this.$v.formulario.id_tipo_documento.$dirty) return errors;
      !this.$v.formulario.id_tipo_documento.required &&
        errors.push("El tipo de documento es requerido");
      return errors;
    },
    nitError() {
      const errors = [];
      if (!this.$v.formulario.numero_documento.$dirty) return errors;

      !this.$v.formulario.numero_documento.required && errors.push("El campo es requerido");


      if (this.formatoDocumento && !this.$v.formulario.numero_documento.regex) {
        errors.push(`El número de documento debe seguir el formato: ${this.formatoDocumento.split('|').join(' o ')}`);
      }

      return errors;
    },
    porcentajeParticipacionError() {
      const errors = [];
      if (!this.$v.formulario.porcentaje_participacion.$dirty) return errors;
      !this.$v.formulario.porcentaje_participacion.required &&
        errors.push("El campo es requerido");
      return errors;
    },
    razonSocialError() {
      const errors = [];
      if (!this.$v.formulario.primer_nombre.$dirty) return errors;
      !this.$v.formulario.primer_nombre.required &&
        errors.push("El campo es requerido");
      return errors;
    },
    matriculaComercioError() {
      const errors = [];
      if (!this.$v.formulario.matricula_comercio.$dirty) return errors;
      !this.$v.formulario.matricula_comercio.required &&
        errors.push("El campo es requerido");
      return errors;
    },
    tiposDocumentosJuridico() {
      return this.tiposDocumentos.filter(
        (tipo) => tipo.id == 23 || tipo.id == 2
      );
    },
  },
  methods: {
    ...mapActions("shareholders", ["getShareholders"]),
    /**
     * Obtiene la lista de tipos de documentos para ser mostrados en el selector
     * de tipo de documento
     */
    async obtenerTiposDocumentos() {
      const response = await this.services.Documentos.getDocumentTypeList({
        id_categoria_documento: 1,
      });
      this.tiposDocumentos = response.data;
    },
    cambiarFormatoDocumento() {
      if (this.formulario.id_tipo_documento != null) {
        // Buscamos en tiposDocumento el tipo de documento con el id almacenado en formulario.id_tipo_documento
        const tipoDocumento = this.tiposDocumentos.find(
          (tipoDocumento) =>
            tipoDocumento.id == this.formulario.id_tipo_documento
        );
        if (tipoDocumento.nombre == "Documento genérico")
          this.formatoDocumento = "";
        else this.formatoDocumento = tipoDocumento.formato;
      } else {
        this.formatoDocumento = "";
      }
    },
    async guardarAccionista() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.guardarAccionistaLoading = true;
        if (this.$route.params.id_accionista) {
          this.formulario.id_sociedad = this.$route.params.id_accionista;
        }
        const response = await this.services.Proveedores.postShareholder(
          this.formulario
        );

        if (response.status == 201) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Accionista agregado correctamente",
          });

          // Reiniciar formulario
          this.formulario = {
            id_tipo_persona: 2,
            id_tipo_documento: 2,
            numero_documento: null,
            porcentaje_participacion: null,
            agente_legal: false,
            primer_nombre: null,
            primer_apellido: "",
            matricula_comercio: null,
          };

          this.$v.$reset();

          this.getShareholders({
            id_sociedad: this.$route.params?.id_accionista,
          });
        }

        this.guardarAccionistaLoading = false;
      }
    },
  },
  created() {
    this.obtenerTiposDocumentos();
  },
};
</script>
<style lang="scss"></style>
